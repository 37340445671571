import React from 'react'
import { Link } from 'react-router-dom'

import PHd from '../data_json/phd.json'

function Phd() {
  return (
    <div className="container my-5">
        <h1 className="text-center" style={{color:"#4437B8"}}>Ph.D</h1>


        <div className="my-4">
        <div className="row row-cols-1 row-cols-md-3 g-4">

        {PHd.PHD && PHd.PHD.map((e,index)=>(

              <div className="col">
              <div className="card h-100 shadow p-3 mb-5 bg-white rounded">
              <img key={index} src={e.image} className="card-img-top rounded-circle mx-auto d-block my-3" alt="..." style={{height:"50%", width:"50%",borderRadius:"50%"}} />
                <div className="card-body">
                  <h5 className="card-title text-center">{e.Name}</h5>
                  <p className="card-text text-center"> <strong>Batch: </strong> {e.batch}</p>
                  <div class="d-grid gap-2">
                    <Link to={`/phdcard/${"PHD"}/${e.id}`} class="btn btn-custom" type="button">View more</Link>
                    
                  </div>
                </div>
              </div>
              </div>

        ))}

        </div>
      </div>

      

      <div className="my-4">
        <div className="row row-cols-1 row-cols-md-3 g-4">

        {PHd.PHDALUMNI && PHd.PHDALUMNI.map((e,index)=>(

              <div className="col">
              <div className="card h-100">
              <img key={index} src={e.image} className="card-img-top rounded-circle mx-auto d-block my-3" alt="..." style={{height:"50%", width:"50%",borderRadius:"50%"}} />
                <div className="card-body">
                  <h5 className="card-title text-center my-3">{e.Name}</h5>
                  {/* <p className="card-text text-center">{e.batch}</p> */}

                  <div class="d-grid gap-2">
                    <Link to={`/phdalumini/${"PHDALUMNI"}/${e.id}`} class="btn btn-custom" type="button">View more</Link>
                    
                  </div>
                </div>
              </div>
              </div>

        ))}

        </div>
      </div>

      

    
    </div>
  )
}

export default Phd