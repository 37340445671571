import React from 'react'
import karan_sir from '../images/karan_sir.png'
import Phd from './Phd'
import Mtech from './Mtech'
import { Link } from 'react-router-dom'
import Collaborators from './Collaborators'


function Team() {
  return (
    <div className="container">
        <h1 className="text-center" style={{color:"#4437B8"}}>Lab Mentor</h1>

        <div className="container d-flex justify-content-center my-5">
        
            <div class="card shadow-lg p-3 mb-5 bg-white rounded" style={{"width": "18rem"}}>
            <img src={karan_sir} className="card-img-top rounded-circle mx-auto d-block my-3" alt="..." style={{height:"50%", width:"50%",borderRadius:"50%"}} />
                <div class="card-body">
                    <h5 class="card-title text-center">Dr. KARAN SINGH</h5>
                    <p class="card-text text-center">Mentor</p>
                    <p class="card-text text-center">Security and Computing Labrotory</p>
                    <div class="d-grid gap-2">
                        <Link to="/Mentor" class="btn btn-custom" type="button">View more</Link>
                    
                  </div>
                </div>
            </div>
        </div>

        <Phd />
        <Mtech />
        
        <Collaborators />
    </div>
  )
}

export default Team