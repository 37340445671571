import React from 'react'

function Footer_copy() {
  return (
    <footer className="bg-body-tertiary text-center text-lg-start shadow-lg">
 
            <div className="text-center p-3" style={{backgroundColor: "rgba(0, 0, 0, 0.05)"}}>
            © 2024 Copyright:
                <a className="text-body" href="/"> Security and Computing Laboratory, JNU, New Delhi</a>
            </div>
            
    </footer>
  )
}

export default Footer_copy