import React from 'react'
import stan_article from './standfoard_reading.pdf'
import camb_article from './cambridge.pdf';
import havard_article from './Havard_article.pdf';
import Phd_thesis from './PhD_Thesis.docx'
import Mtech from './M.tech_thesis.docx'
import Submission from '../Submission/Submission'

function Material_link() {
  return (
    <div className="container my-5">

    <h1 className="card-text text-center my-5" style={{color:"#4437B8"}}>Important Links and Materials</h1>

      <div className="row">
        <div className="col-sm-6 mb-3 mb-sm-0">
          <div className="card border-0">
            <div className="card-body">
              
              <h3 className="card-text my-5" style={{color:"#4437B8"}}>Diagram making tools</h3>

              <a href="https://app.diagrams.net/" type="button" className="btn btn-warning"> <strong>Draw.io</strong> </a>
              <a href="https://www.canva.com/en_in/" type="button" className="btn btn-primary mx-3" style={{backgroundColor:"#8b3dff" ,borderColor:"#8b3dff"}}> <strong>Canva</strong> </a>

              <h3 className="card-text my-5" style={{color:"#4437B8"}}>Important Links for Courses</h3>

              <a href="https://www.geeksforgeeks.org/" type="button" className="btn btn-success"> <strong>Geeks For Geeks</strong> </a>

              <a href="https://www.javatpoint.com/" type="button" className="btn btn-danger mx-3"> <strong>Java T Point</strong> </a>

              <a href="https://swayam.gov.in/" type="button" className="btn btn-primary my-3" style={{backgroundColor:"#428bca", borderColor:"#428bca"}}> <strong>Swayam</strong> </a>

              <a href="https://www.coursera.org/" type="button" className="btn btn-primary mx-3"> <strong>Coursera</strong> </a>

              <h3 className="card-text my-5" style={{color:"#4437B8"}}>How to read a Research article</h3>

              <a href={stan_article} type="button" className="btn btn-primary mx-1" download="stanford.pdf" style={{backgroundColor:"#b1040e", border:"#b1040e"}}> <strong>Stanford University</strong> </a>

              <a href={camb_article} type="button" className="btn btn-primary mx-1 my-3" download="cambridge.pdf" style={{backgroundColor:"#fd7e14", border:"#fd7e14"}}> <strong>Cambridge University</strong> </a>

              <a href={havard_article} type="button" className="btn btn-primary mx-1" download="Harvard.pdf" style={{backgroundColor:"#a51c30", border:"#a51c30"}}> <strong>Harvard University</strong> </a>

              <h3 className="card-text my-5" style={{color:"#4437B8"}}>How to Structure and write Ph.D Thesis</h3>

              <a href="https://scientific-publishing.webshop.elsevier.com/manuscript-preparation/how-to-structure-your-phd-thesis/" type="button" className="btn btn-primary" style={{backgroundColor:"#ff6900", border:"#ff6900"}} target="_blank"> <strong>Elsevier</strong> </a>

              <a href="https://www.robots.ox.ac.uk/~gari/teaching/ThesisTemplate/GuideToWritingYourThesis.pdf" type="button" className="btn btn-primary mx-3" style={{backgroundColor:"#002147", border:"#002147"}} target="_blank"> <strong>Oxford University</strong> </a>

              <a href="https://cambridgescholars.com/resources/pdfs/978-1-5275-3681-4-sample.pdf" type="button" className="btn btn-primary mx-3 my-3" style={{backgroundColor:"#fd7e14", border:"#fd7e14"}} target="_blank"> <strong>Cambridge University</strong> </a>

              

              
              
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card my-5">
            <div className="card-body">
              <h2 className="card-title" style={{color:"#C71585"}}>Thesis and Disseration format</h2>
              <h3 className="card-text my-5" style={{color:"#4437B8"}}>Ph.D Thesis format</h3>

              <a href={Phd_thesis} type="button" className="btn btn-primary" target="_blank" download="PhD_thesis_format"> <strong>Word</strong> </a>

              <a href="https://github.com/SclJnu/Ph.D-Thesis" type="button" className="btn btn-success mx-3" target="_blank"> <strong>Overleaf</strong> </a>
              
              <h3 className="card-text my-5" style={{color:"#4437B8"}}>M.Tech Disseration Format</h3>

              <a href={Mtech} type="button" className="btn btn-primary" target="_blank" download="M.Tech_thesis_format"> <strong>Word</strong> </a>

              <a href="https://github.com/SclJnu/M.tech-Thesis" type="button" className="btn btn-success mx-3" target="_blank"> <strong>Overleaf</strong> </a>
              

              
            </div>
          </div>
        </div>
      </div>

      <Submission/>

    </div>
  )
}

export default Material_link