import React from 'react'

function Funding() {
  return (
    <div className="container">
        <h1 className="text-center" style={{color:"#4437B8"}}>Lab is Sponsored by</h1>

        <div class="row row-cols-1 row-cols-md-3 g-4 my-5">
            <div class="col">
              <div class="card shadow-lg p-3 mb-5 bg-white rounded h-100">
                
                <div class="card-body text-center">
                  <h1 class="card-title" style={{color:"#9B2CD2"}}> <strong>UPE-II</strong> </h1>
                  
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card shadow-lg p-3 mb-5 bg-white rounded h-100">
                
                <div class="card-body text-center">
                  
                  <h1 class="card-title" style={{color:"#E31B1B"}}> <strong>PROJECT GRANT</strong> </h1>
                 
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card shadow-lg p-3 mb-5 bg-white rounded h-100">
                
                <div class="card-body text-center">
                  
                  <h1 class="card-title" style={{color:"#6600CC"}}> <strong>UGC STARTUP GRANT</strong> </h1>
                 
                </div>
              </div>
            </div>
          </div>
        
    </div>
  )
}

export default Funding