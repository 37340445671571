import React from 'react'

function Publications() {

  // "U. Jabeen, K. Singh and S. Vats, Credit Card Fraud Detection Scheme Using Machine Learning and Synthetic Minority Oversampling Technique (SMOTE), 2023 5th International Conference on Inventive Research in Computing Applications (ICIRCA), Coimbatore, India, 2023, pp. 122-127, doi: 10.1109/ICIRCA57980.2023.10220646"

  const Journal =[

    "A. K. Yadav, K. Singh, P. K. Srivastava, and P. S. Pandey, “I-MEREC-T: Improved MEREC-TOPSIS scheme for optimal network selection in 5G heterogeneous network for IoT,” Internet of Things, vol. 22, no. 100748, p. 100748, 2023.",

    "A. K. Yadav, K. Singh, A. H. Amin, L. Almutairi, T. R. Alsenani, and A. Ahmadian, “A comparative study on consensus mechanism with security threats and future scopes: Blockchain,” Comput. Commun., vol. 201, pp. 102–115, 2023.",

    "S. Kumari, K. Singh et al., “A novel approach for continuous authentication of mobile users using reduce feature elimination (RFE): A machine learning approach,” Mob. Netw. Appl., vol. 28, no. 2, pp. 767–781, 2023.",


    "T. Khan, K. Singh et al., “An efficient trust-based decision-making approach for WSNs: Machine learning oriented approach,” Comput. Commun., vol. 209, pp. 217–229, 2023.",

    "A. Kumar, K. Singh et al., “An efficient and reliable ultralightweight RFID authentication scheme for healthcare systems,” Comput. Commun., vol. 205, pp. 147–157, 2023.",


    "V. Pathak, K. Singh  et al., “Efficient compression sensing mechanism based WBAN system using blockchain,” Security and Communication Networks, vol. 2023, no. 1, 2023.",

    "T. Khan and K. Singh, “RTM: Realistic weight-based reliable trust model for large scale WSNs,” Wirel. Pers. Commun., vol. 129, no. 2, pp. 953–991, 2023.",

    "T. Khan, K. Singh, K. Ahmad, and K. A. B. Ahmad, “A secure and dependable trust assessment (SDTS) scheme for industrial communication networks,” Sci. Rep., vol. 13, no. 1, p. 1910, 2023.",

    "G. S. Rawat and K. Singh, “Joint beacon frequency and beacon transmission power adaptation for internet of vehicles,” Trans. Emerg. Telecommun. Technol., vol. 33, no. 10, 2022.",

    "I. Dohare, K. Singh, A. Ahmadian, S. Mohan, and P. Kumar Reddy M, “Certificateless aggregated signcryption scheme (CLASS) for cloud-fog centric industry 4.0,” IEEE Trans. Industr. Inform., vol. 18, no. 9, pp. 6349–6357, 2022.",

    "T. Khan, K. Singh, M. Manjul, M. N. Ahmad, A. M. Zain, and A. Ahmadian, “A temperature-aware trusted routing scheme for sensor networks: Security approach,” Comput. Electr. Eng., vol. 98, no. 107735, p. 107735, 2022.",

    "M. Shariq, K. Singh, P. K. Maurya, A. Ahmadian, and D. Taniar, “Correction to: AnonSURP: an anonymous and secure ultralightweight RFID protocol for deployment in internet of vehicles systems,” J. Supercomput., vol. 78, no. 6, pp. 8603–8603, 2022.",

    "M. Shariq and K. Singh, “A vector-space-based lightweight RFID authentication protocol,” Int. J. Inf. Technol., vol. 14, no. 3, pp. 1311–1320, 2022.",

    "M. Shariq and K. Singh, “A secure and lightweight RFID-enabled protocol for IoT healthcare environment: A vector space based approach,” Wirel. Pers. Commun., vol. 127, no. 4, pp. 3467–3491, 2022.",

    "N. Sharma, B. M. Singh, and K. Singh, “Analysis of packet transmission protocols formultimedia applications of WBANs in cognitive behavioral therapy,” Multimed. Tools Appl., 2022.",
 
    "M. Shariq, K. Singh, C. Lal, M. Conti, and T. Khan, “ESRAS: An efficient and secure ultra-lightweight RFID authentication scheme for low-cost tags,” Comput. Netw., vol. 217, no. 109360, p. 109360, 2022.",

    "T. Khan, K. Singh, and K. C. Purohit, “ICMA: An efficient integrated congestion control approach,” Recent Pat. Eng., vol. 14, no. 3, pp. 294–309, 2021.",

    "V. Pathak and K. Singh, “Secure and efficient WBANs algorithm with authentication mechanism,” J. Intell. Fuzzy Syst., vol. 41, no. 5, pp. 5525–5534, 2021.",

    "M. Shariq, K. Singh, P. K. Maurya, A. Ahmadian, and M. R. K. Ariffin, “URASP: An ultralightweight RFID authentication scheme using permutation operation,” Peer Peer Netw. Appl., vol. 14, no. 6, pp. 3737–3757, 2021.",

    "N. Sharma, B. M. Singh, and K. Singh, “QoS-based energy-efficient protocols for wireless sensor network,” Sustain. Comput. Inform. Syst., vol. 30, no. 100425, p. 100425, 2021.",

    "L. Banda and K. Singh, “Enhance the quality of collaborative filtering using tagging. Recent Advances in Computer Science and Communications (Formerly: Recent Patents on),” Computer Science), vol. 14, no. 4, pp. 1016–1029, 2021.",

    "T. Khan et al., “ETERS: A comprehensive energy aware trust-based efficient routing scheme for adversarial WSNs,” Future Gener. Comput. Syst., vol. 125, pp. 921–943, 2021.",

    "A. Aziz et al., “Compressive sensing based routing and data reconstruction scheme for IoT based WSNs,” J. Intell. Fuzzy Syst., vol. 41, no. 1, pp. 19–35, 2021.",

    "B. Chaudhary and K. Singh, “A Blockchain enabled location-privacy preserving scheme for vehicular ad-hoc networks,” Peer Peer Netw. Appl., 2021.",

    "L. Banda and K. Singh, “Improving scalability, sparsity and cold start user issues in collaborative tagging with incremental clustering and trust. Recent Advances in Computer Science and Communications (Formerly: Recent Patents on),” Computer Science), vol. 14, no. 2, pp. 429–438, 2021.",

    "N. Sharma, H. Chadha, K. Singh, B. M. Singh, and N. Pathak, “A novel hybrid clustering based transmission protocol for wireless body area networks,” Comput. Mater. Contin., vol. 69, no. 2, pp. 2459–2473, 2021.",

    "T. Khan and K. Singh, “TASRP: a trust aware secure routing protocol for wireless sensor networks,” Int. J. Innov. Comput. Appl., vol. 12, no. 2/3, p. 108, 2021.",

    "M. Shariq and K. Singh, “A novel vector-space-based lightweight privacy-preserving RFID authentication protocol for IoT environment,” J. Supercomput., vol. 77, no. 8, pp. 8532–8562, 2021.",

    "A. K. Yadav, K. Singh, A. Ahmadian, S. Mohan, S. B. Hussain Shah, and W. S. Alnumay, “EMMM: Energy-efficient mobility management model for context-aware transactions over mobile communication,” Sustain. Comput. Inform. Syst., vol. 30, no. 100499, p. 100499, 2021.",

    "I. Dohare and K. Singh, “Green communication in sensor enabled IoT: integrated physics inspired meta-heuristic optimization based approach,” Wirel. Netw., vol. 26, no. 5, pp. 3331–3348, 2020.",

    "M. Manjul, R. Mishra, K. Singh, L. H. Son, M. Abdel-Basset, and P. H. Thong, “Single rate based extended logarithmic multicast congestion control,” J. Ambient Intell. Humaniz. Comput., vol. 11, no. 7, pp. 2779–2791, 2020.",

    "S. Sahana and K. Singh, “Cluster based localization scheme with backup node in underwater wireless sensor network,” Wirel. Pers. Commun., vol. 110, no. 4, pp. 1693–1706, 2020.",

    "D. Kumari, K. Singh, and M. Manjul, “Performance evaluation of Sybil attack in cyber physical system,” Procedia Comput. Sci., vol. 167, pp. 1013–1027, 2020.",

    "A. Aziz, W. Osamy, A. M. Khedr, A. A. El-Sawy, and K. Singh, “Grey Wolf based compressive sensing scheme for data gathering in IoT based heterogeneous WSNs,” Wirel. Netw., vol. 26, no. 5, pp. 3395–3418, 2020.",

    "A. Aziz, K. Singh, W. Osamy, and A. M. Khedr, “An efficient compressive sensing routing scheme for internet of things based wireless sensor networks,” Wirel. Pers. Commun., vol. 114, no. 3, pp. 1905–1925, 2020.",

    "N. Sharma, K. Singh, and B. M. Singh, “A load based transmission control protocol for wireless sensor networks,” Int. J. Inf. Technol., vol. 12, no. 2, pp. 577–583, 2020.",

    "V. Srivastava, S. Tripathi, K. Singh, and L. H. Son, “Energy efficient optimized rate based congestion control routing in wireless sensor network,” J. Ambient Intell. Humaniz. Comput., vol. 11, no. 3, pp. 1325–1338, 2020.",

    "V. Shankar and K. Singh, “An improved user authentication scheme on smartphone using dominating attribute of touch data,” J. Discrete Math. Sci. Cryptogr., vol. 22, no. 8, pp. 1549–1561, 2019.",

    "A. Aziz, K. Singh, W. Osamy, and A. M. Khedr, “Effective algorithm for optimizing compressive sensing in IoT and periodic monitoring applications,” J. Netw. Comput. Appl., vol. 126, pp. 12–28, 2019.",

    "T. Khan et al., “A novel and comprehensive trust estimation clustering based approach for large scale wireless sensor networks,” IEEE Access, vol. 7, pp. 58221–58240, 2019.",

    "S. Sahana and K. Singh, “Fuzzy based energy efficient underwater routing protocol,” J. Discrete Math. Sci. Cryptogr., vol. 22, no. 8, pp. 1501–1515, 2019.",

    "M. K. Jangir and K. Singh, “HARGRURNN: Human activity recognition using inertial body sensor gated recurrent units recurrent neural network,” J. Discrete Math. Sci. Cryptogr., vol. 22, no. 8, pp. 1577–1587, 2019.",

    "D. P. Sahu et al., “Heuristic search based localization in mobile computational grid,” IEEEAccess, vol. 7, pp. 78652–78664, 2019.",

    "V. Shankar and K. Singh, “An intelligent scheme for continuous authentication of smartphone using deep auto encoder and softmax regression model easy for user brain,” IEEE Access, vol. 7, pp. 48645–48654, 2019.",

    "B. Chaudhary and K. Singh, “Pseudonym generation using genetic algorithm in vehicular ad hoc networks,” J. Discrete Math. Sci. Cryptogr., vol. 22, no. 4, pp. 661–677, 2019.",

    "A. Aziz and K. Singh, “Lightweight security scheme for internet of things,” Wirel. Pers. Commun., vol. 104, no. 2, pp. 577–593, 2019.",

    "A. Aziz, K. Singh, A. Elsawy, W. Osamy, and A. M. Khedr, “GWRA: grey wolf based reconstruction algorithm for compressive sensing signals,” PeerJ Comput. Sci., vol. 5, no. e217, p. e217, 2019.",

    "T. Khan and K. Singh, “Resource management based secure trust model for WSN,” J. Discrete Math. Sci. Cryptogr., vol. 22, no. 8, pp. 1453–1462, 2019.",

    "A. Aziz, K. Singh, W. Osamy, and A. M. Khedr, “Optimising compressive sensing matrix using Chicken Swarm Optimisation algorithm,” IET Wirel. Sens. Syst., vol. 9, no. 5, pp. 306–312, 2019.",




    "N. Sharma, K. Singh, and B. M. Singh, “An enhanced-simple protocol for wireless body area networks,” Journal of Engineering Science and Technology, vol. 13, no. 1, pp. 196–210, 2018.",

    "A. Singh, A. K. Awasthi, K. Singh, and P. K. Srivastava, “Modeling and analysis of worm propagation in wireless sensor networks,” Wirel. Pers. Commun., vol. 98, no. 3, pp. 2535–2551, 2018.",

    "K. Singh, K. Singh, L. H. Son, and A. Aziz, “Congestion control in wireless sensor networks by hybrid multi-objective optimization algorithm,” Comput. Netw., vol. 138, pp. 90–107, 2018.",

    "N. Sharma, K. Singh, and B. M. Singh, “Sectorial stable election protocol for wireless sensor network,” Journal of Engineering Science and Technology, vol. 13, no. 5, pp. 1222–1236, 2018.",



    "A. Singh, A. K. Awasthi, and K. Singh, “Cryptanalysis and improvement in user authentication and key agreement scheme for wireless sensor network,” Wirel. Pers. Commun., vol. 94, no. 3, pp. 1881–1898, 2017.",

    "S. Kumar Choudhary, K. Singh, and V. Kumar Solanki, “Spiking activity of a LIF neuron in distributed delay framework,” Int. J. Interact. Multimed. Artif. Intell., vol. 3, no. 7, p. 70, 2016.",

    "D. K. Yadav and K. Singh, “A combined approach of Kullback–Leibler divergence and background subtraction for moving object detection in thermal video,” Infrared Phys. Technol., vol. 76, pp. 21–31, 2016.",

    "S. K. Choudhary and K. Singh, “Temporal information processing and stability analysis of the MHSN neuron model in DDF,” Int. J. Interact. Multimed. Artif. Intell., vol. 4, no. 2, p. 40, 2016.",

    "K. Singh and R. S. Yadav, “Efficient multicast congestion control,” Wirel. Pers. Commun., vol. 78, no. 2, pp. 1159–1176, 2014."



  ]

  const Conference =[
   "Yadav A.K., Singh K. (2020) Comparative Analysis of Consensus Algorithms of Blockchain Technology. In: Hu YC., Tiwari S., Trivedi M., Mishra K. (eds) Ambient Communications and Computer Systems. Advances in Intelligent Systems and Computing, vol 1097. Springer, Singapore. https://doi.org/10.1007/978-981-15-1518-7_17, Feb 2020",

   "Gaur D., Mehrotra D., Singh K. (2020) Particulate Matter Estimation Based on Haze Degree Factor. In: Pant M., Sharma T., Verma O., Singla R., Sikander A. (eds) Soft Computing: Theories and Applications. Advances in Intelligent Systems and Computing, vol 1053. Springer, Singapore. https://doi.org/10.1007/978-981-15-0751-9_70 Feb 2020.",

   "Latha banda and karan singh, “Improving Scalability And Sparsity Issues In Hybrid Recommended Systems Using Real Value GA”, Book Chapter in the 2 nd International Conference on Communication and Computing Systems (ICCCS 2018), Gurgaon, India, 1-2 December, 2018, India.",

   "Shankar, Vishnu, and Karan Singh. Survey on Mobile Authentication Schemes. In Proceedings of 3rd International Conference on Internet of Things and Connected Technologies (ICIoTCT), pp. 26-27, March, 2018. ISSN: 1556-5068, India",

   "Jangir, Mahendra Kumar, Karan Singh, and Vishnu Shankar; Economical Mobile Healthcare and Wellness Application System In Proceedings of 3rd International Conference on Internet of Things and Connected Technologies (ICIoTCT), pp. 26-27, March, 2018. ISSN: 1556-5068, India",

   "Ahmed Aziz, Karan Singh and Walid Osamy, “Lightweight Simulated Annealing Based Reconstruction Algorithm for Sparse Binary Signals” Proceedings of the 2 nd International Conference on Communication and Computing Systems (ICCCS 2018), Gurgaon, India, 1-2 December, 2018. (Best Paper).",

   "Tayyab Khan and Karan Singh “SE-TEM: SIMPLE AND EFFICIENT TRUST EVALUATION MODEL FOR WSN”, International Conference on Advanced Computing and Software Engineering (ICACSE-16), at KNIT Sultanpur, October 14-15, 2016.",

   "Jangir M.K., Singh K. (2017) QoS and QoE in Wireless Multicast Communication, Advances in Computing and Data Sciences. ICACDS 2016. Communications in Computer and Information Science, vol 721. Springer, Singapore, ISBN No 978-981-10-5427-3.",

   "Ahmed Aziz and Karan Singh, “Adaptive Compressive Sensing Based Routing Algorithm for Internet of Things and Wireless Sensor Networks,” as chapter in Communication and Computing Systems book of the International Conference on Communication and Computing Systems (ICCCS 2016), Gurgaon, India, 9-11 September, 2016. (Best Paper).",

   "Dileep Kumar Yadav, Karan Singh, Swati Kumari “Challenging Issues of Video Surveillance System using Internet of Things in Cloud Environment”, ICACDS-2016, Springer, at KEC, Ghaziabad, pp. 1-8, Nov. 11-12, 2016. (ISBN: 978-981-10-5426-6, Chapter-49).",

   "Dileep Kumar Yadav, Karan Singh, “MOVING OBJECT DETECTION FOR VISUAL SURVEILLANCE USING QUASI-EUCLIDIAN DISTANCE”, 2nd International Conference on Computer, Communication and Technology (IC3T-2015), Lecture Notes in Computer Science, AISC Series, Springer, page 1-8, July 24-26, 2015. (ISBN: 978-81-322-2526-3).",

   "D.P. Sahu, Karan Singh, S.Prakash, “TASK SCHEDULING IN COMPUTATIONAL GRID USING NSGA II”, 2 nd International Conference on Networks, Information and Communications 2015(ICNIC 2015), Bengaluru, India.",

   "Dileep Kumar Yadav, Karan Singh “OBJECT DETECTION IN DYNAMIC BACKGROUND FOR VISUAL SURVEILLANCE APPLICATIONS”, 2nd International Conference on Computing for Global Sustainable Development (9th IndiaCOM-2015), IEEE, page 1601-1606, March 11-13, 2015. (ISSN: 0973-7529).",

   "Saket Kumar Choudhary, Karan Singh and Sunil Kumar Bharti, “VARIABILITY IN SPIKING PATTERN OF LEAKY INTEGRATE-AND-FIRE NEURON DUE TO EXCITATORY AND INHIBITORY POTENTIALS,” 2nd International Conference on “Computing for Sustainable Global Development”, Proceedings of the 9th INDIACom; INDIACom-2015, Bharati Vidyapeeth’s Institute of Computer Applications and Management (BVICAM), New Delhi (INDIA), 11th – 13th March,2015, pp. 2025 – 2030 (IEEE Explorer).",

   "Saket Kumar Choudhary and Karan Singh, “INFORMATION PROCESSING IN NEURON WITH EXPONENTIAL DISTRIBUTED DELAY,” International Conference on Recent Trends in Mathematics (ICRTM 2015) , Department of Mathematics, University of Allahabad, July 10-12, 2015 (abstract only).",

   "Subrata Sahana, Karan Singh, Rajesh Kuma and Sanjoy Das, “A REVIEW OF UNDERWATER WIRELESS SENSOR NETWORK ROUTING PROTOCOLS AND CHALLENGES” published in Springer proceeding of 50th Golden Jubilee Annual Convention on “Digital Life”, 02nd – 05th December, 2015, New Delhi 978-981-10-8536-9.",

   "Shailendra Singh and Karan Singh “PERFORMANCE ANALYSIS OF VULNERABILITY DETECTION SCANNERS FOR WEB SYSTEMS” published in Springer proceeding of 50th Golden Jubilee Annual Convention on “Digital Life”, 02nd – 05th December, 2015, New Delhi, 978-981-10-8536-9.",

   "Y Mohan, C Rama Krishna and Karan Singh “PERFORMANCE EVALUATION OF MULTICAST SOURCE AUTHENTICATION SCHEME” published in Springer proceeding of 50th Golden Jubilee Annual Convention on “Digital Life”, 02nd – 05th December, 2015, New Delhi, 978-981-10-8536-9.",

   "Vishnu Shankar and Karan Singh “APPLICATIONS OF ATTRIBUTE BASED ENCRYPTION IN CLOUD COMPUTING ENVIRONMENT” published in Springer proceeding of 50th Golden Jubilee Annual Convention on “Digital Life”, 02nd – 05th December, 2015, New Delhi, 978-981-10-6620-7.",

   "Jaiveer Singh, Karan Singh CONGESTION CONTROL IN VEHICULAR AD HOC NETWORK: A REVIEW, 50th Golden Jubilee Annual Convention on “Digital Life”, published in Springer proceeding of 50th Golden Jubilee Annual Convention on “Digital Life”, 02nd – 05th December, 2015, New Delhi, isbn 978-981-10-6005-2",

   "Vipin Kumar Rathi and Karan Singh “SDN LAYER 2 SWITCH SIMULATION USING MININET AND OPENDAYLIGHT” published in Springer proceeding of 50th Golden Jubilee Annual Convention on “Digital Life”, 02nd – 05th December, 2015, New Delhi, 978-981-10-8536-9",

   "Akansha Singh , Amit K. Awasthi and Karan Singh “A KEY AGREEMENT ALGORITHM BASED ON ECDSA FOR WIRELESS SENSOR NETWORK”, Smart Innovation, Systems and Technologies, Vol. 44, pp 143-149, Springer, 2015 ISBN 2190-3018",

   "Akansha Singh, Amit K. Awasthi and Karan Singh “ISSUES RELATED TO SECURITY OF A SENSOR NETWORK USED IN HEALTH CARE APPLICATION” IEEE conference held at DIT Greater Noida Feb 2015.",

   "ManishaYadav, Sandeep Sharma, Karan Singh, “Comparison of Various Scheduling Techniques in Real Time Message Transmission”, IEEE sponsored National Conference on Energy Power and Intelligent Control Systems, 28-29 march, 2014.",

   "Manisha Yadav, Sandeep Sharma, Karan Singh, EFFICIENT KEY MANAGEMENT IN SECURE GROUP COMMUNICATION IN MULTICAST NETWORKS in National Conference on Computer Intelligence and Robotics 2014.",

   "Manisha Yadav, Sandeep Sharma, Karan Singh A APPROACH TO KEY MANAGEMENT TO SECURE GROUP COMMUNICATIONS IN MULTICAST NETWORKS in 9th national conference on Smarter Approaches in computing Technologies and Applications 2014.",

   "Karan Singh and Rama Shankar Yadav “INTEGRATED APPROACH FOR MULTICAST SOURCE AUTHENTICATION AND CONGESTION CONTROL” published in LNCS Springer (proceeding of 9th International Conference on Heterogeneous Networking for Quality, Reliability, Security and Robustness (Qshine 2013) at Gautam Buddha University, Greater Noida, Jna 11-12, 2013, 978-3-642- 37949-9",

   "Karan Singh and Rama Shankar Yadav “MULTICAST CONGESTION CONTROL IN ADVERSARY ENVIROMENT” published in the proceeding of International on Industrial and Intelligent Information (ICIII 2012) at Academic of Technology, Singapore from March 17-18, 2012, 978-981-07-1710-0.",

   "Karan Singh with Sunil Kumar “LOGARITHM BASED MULTICAST CONGESTION CONTROL” published in the proceeding of 2012 International on Industrial and Intelligent Information (ICIII 2012) at Academic of Technology, Singapore from March 17-18, 2012, 978-981-07-1710-0",

   "Karan Singh, Rama Shankar Yadav and Grish Kumar Gupta “MINIMIZATION THE EFFECT OF IGMP LEAVE LATENCY IN LAYERED MULTICAST CONGESTION CONTROL” published in the IEEE International Conference on Advances in Computing, Control, and Telecommunication Technologies, (ACT 2009), India, 28th- 29th December 2009, 978-0-7695-3915-7.",

   "Karan Singh, Rama Shankar Yadav and Grish Kumar Gupta “EFFECTIVE QUEUE MANAGEMENT FOR LAYERED MULTICAST” published in IEEE Bangalore Section - 18th Annual Symposium on Emerging Needs in Computing, Communication, Signals and Power ENC2SP 2009, Bangalore 29 August 2009.",

   "Karan Singh with Rama Shankar Yadav, Amit Sharma “ADAPTIVE MULTICAST SOURCE AUTHENTICATION” published in IEEE International Advance Computing Conference, march 2009 at Thapar University, Patiala, Punjab, India. ISBN 978-1-4244-2928-8"

  ]

  const BookChapter =[

    "Taylor & Francis CRC Special Issue: Network and Cryptology” Edition 2020.",
    "Taylor & Francis CRC Special Issue: Network and Cryptology” Edition 2019.",
    "Taylor & Francis CRC Edited Book: Communication and Computing Systems: Proceedings of the International Conference on Communication and Computing Systems (ICCCS 2018), Gurgaon, India, 1-2 December, 2018.",
    "Journal Guest Editor: MONET Springer, 2017 (SGIoT).",
    "Taylor & Francis CRC Edited Book: Communication and Computing Systems: Proceedings of the International Conference on Communication and Computing Systems (ICCCS 2016), Gurgaon, India, 9-11 September, 2016",
    "Springer Edited Book: QUALITY, RELIABILITY, SECURITY AND ROBUSTNESS IN HETEROGENEOUS NETWORKS” Publication Springer Lecture Notes of the Institute for Computer Sciences, Social Informatics and Telecommunications Engineering, Vol. 115, PP 1-1007, ISBN: 978-3-642-37948-2 Edition 2013."
   
  ]

  const Patents=[

    "Karan Singh, Manisha Manjul, Arpit Jain, Tayyab Khan and Vinay Pathak “SMART-TRANSIT: SMART TRAFFIC MONITORING SYSTEM USING IOT AND AI TECHNIQUES”, India, Published.",
    "Vinay Pathak, Karan Singh, Manisha Manjul and Arpit Jain “TO DEVELOP EFFICIENT SYSTEM FOR DETECTING PARKINSON DISEASE USING IOT”, India, Published.",
    "NEELAM SHARMA, BRIJMOHAN SINGH, KARAN SINGH, ITCP- WIRELESS SENSOR NETWORK: INTELLIGENT TRANSMISSION CONTROL PROTOCOL FOR WIRELESS SENSOR NETWORK, Publisehed.",
    "Arpit Jain and Karan Singh, Manisha Manjul, et. al “IMT: VOICE-BASED MOBILE BANKING”, Australia , Granted"
  ]


  return (
    <div className="container my-5">
      <div className="container">

      <h1 className='text-center' style={{color: "#4437B8"}}> <strong>List of Publications</strong> </h1>

      <div className="p-3 mb-2 bg-dark bg-gradient text-white my-5"><h3>Journal</h3></div>
      <ul>
        {Journal.map((e,index)=>(
          
          <li style={{textAlign:"justify"}}>{e}</li>

        ))}
      </ul>
      </div>

      <div className="container">
      <div className="p-3 mb-2 bg-dark bg-gradient text-white my-5"><h3>Conference</h3></div>

      {/* <h4 className="my-3 card-text" style={{color:"#4437B8"}}>Data will update soon....</h4> */}

      <ul>
        {Conference.map((e,index)=>(

          <li style={{textAlign:"justify"}}>{e}</li>

        ))}
      </ul>

      </div>

      <div className="container">
      <div className="p-3 mb-2 bg-dark bg-gradient text-white my-5"><h3>Journal Editorship/Book Authorship </h3></div>

      <ul>
        {BookChapter.map((e,index)=>(

          <li style={{textAlign:"justify"}}>{e}</li>

        ))}
      </ul>

      </div>

      <div className="container">
      <div className="p-3 mb-2 bg-dark bg-gradient text-white my-5"><h3>Patents</h3></div>

      <ul>
        {Patents.map((e,index)=>(

          <li style={{textAlign:"justify"}}>{e}</li>

        ))}
      </ul>

      </div>
    </div>
  )
}

export default Publications