import React from 'react'
import cover from '../images/cover.jpg'
import Slider from '../Slider/Slider';
import {motion} from 'framer-motion';
import Logo from './logo.png'

function Home() {
  return (
    <>
    <div className=" container my-3">

        <figure className="text-center">
        <blockquote className="blockquote">
            <strong>At the end of the day,<span style={{color:"#4437B8"}}>the goals are  simple</span> safety and security.</strong>
        </blockquote>
        <figcaption className="blockquote-footer" >
            <cite title="Source Title" style={{color:"#4437B8"}}>Jodi Rell</cite>
        </figcaption>
        </figure>

        <div className="card text-center border-0">
            <div className="card-body">
            <motion.h1 initial= {{y:300}}
                        whileInView={{y: 0}}
                        transition={{
                            duration: 1,
                            delay: 0.1
                        }}
                        className="card-title"
                        style={{color:"#C71585"}}>SECURITY AND COMPUTING LABORATORY</motion.h1>


            <motion.h5 initial={{x:-300}}
                        whileInView={{x: 0}}
                        transition={{
                            duration: 1.1,
                            delay: 0.1
                            }} className='card-text' style={{color:"#8a2be2"}}>LAB SPONSORED BY UPE-II, PROJECT GRANT AND UGC STARTUP GRANT</motion.h5>
            </div>
        </div>

        {/* <div className="card text-bg-dark">
            <img src={cover} className="card-img h-30 w-100 opacity-25" alt="..." style={{height:"25em"}}/>
            <div className="card-img-overlay" style={{textAlign:"center", marginTop:"10%"}}>
                 <motion.h1 initial= {{y:300}}
                                                    whileInView={{y: 0}}
                                                    transition={{
                                                        duration: 1,
                                                        delay: 0.1
                                                    }}
                                                    className="card-title"
                                                    style={{color:"#FFF8AF"}}>SECURITY AND COMPUTING LABORATORY</motion.h1>
                <motion.p initial={{x:-300}}
                                                whileInView={{x: 0}}
                                                transition={{
                                                    duration: 1.1,
                                                    delay: 0.1
                                                }} className='card-text' style={{color:"#FFF8AF"}}> This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</motion.p>
            </div>
        </div> */}


        <div className="container my-5">
            <figure className="text-center">
            <blockquote className="blockquote">
                <motion.h3 initial={{x: -300}}
                                whileInView={{x: 0}}
                                transition= {{
                                    duration: 1,
                                    delay: 0.1
                                }}
                                style={{color: "#4437B8"}}>JAWAHARLAL NEHRU UNIVERSITY</motion.h3>
            </blockquote>
            <figcaption className="blockquote-footer">
                <h5 className="text-center" style={{color:"#E31B1B"}}>NEW DELHI, INDIA </h5>
            </figcaption>
            </figure>
        </div>

        


    </div>

    </>
    
    
  )
}

export default Home