import React from 'react'

function Footer() {
  return (
    <div className="container my-5">

<div className="row">
  <div className="col-sm-6 mb-3 mb-sm-0">
    <div className="card border-0">
      <div className="card-body">
      <div className="ratio ratio-16x9 my-5">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.9280434108077!2d77.1628792752856!3d28.541882475714335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1dc476d79b4d%3A0xa2365e11e7b695fd!2z4KS44KWN4KSV4KWC4KSyIOCkkeCkq-CkvCDgpJXgpILgpKrgpY3gpK_gpYLgpJ_gpLAg4KSU4KSwIOCkuOCkv-CkuOCljeCkn-CkruCljeCkuCDgpLjgpL7gpIfgpILgpLjgpYfgpLg!5e0!3m2!1shi!2sin!4v1717656689206!5m2!1shi!2sin" style={{border:"0"}} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
      </div>
    </div>
  </div>
  <div className="col-sm-6">
    <div className="card my-5 border-0">
      <div className="card-body">
        <h2 className="card-title text-end">Address</h2>
        <p className="card-text text-end">School of Computer and Systems Sciences</p>
        <div className="d-md-flex justify-content-md-end">
            <a href="https://jnu.ac.in/scss" className="btn btn-primary me-md-2" target="_blank">Jawaharlal Nehru University, New Delhi, India</a>
        </div>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}

export default Footer