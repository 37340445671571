import React from 'react'
import quote from '../images/quote.png';
import {motion} from 'framer-motion';
import Cyber from '../images/what-is-cybersecurity.png';
import Blockchain from '../images/Blockchain.png'
import Ai from '../images/ai_for_security.png';
import Authentication from '../images/authentication.png';
import Trust from '../images/Trust.png'
import scl from '../images/scl.png'

import { useInView } from 'react-intersection-observer';

function About() {
  const { ref, inView } = useInView();
  return (
    <div className="container rounded">

      <div className="container">
            <figure className="text-center">
            <blockquote className="blockquote">
                <motion.h3 initial={{x: -300}}
                                whileInView={{x: 0}}
                                transition= {{
                                    duration: 1,
                                    delay: 0.1
                                }}
                                style={{color: "#4437B8"}}>Security used to be an inconvenience sometimes</motion.h3>
            </blockquote>
            <figcaption className="blockquote-footer">
                <h5 className="text-center" style={{color:"#E31B1B"}}>but now it's a necessity all the time </h5>
                
            </figcaption>
            </figure>
        </div>

        <div className="container">
                  <figure className="text-center">

                  <motion.img src={scl} initial={{x: -300}}
                                      whileInView={{x: 0}}
                                      transition= {{
                                          duration: 1,
                                          delay: 0.1
                                      }}
                                      className="" style={{color: "#4437B8"}} height="320px" width="320px" />

                  <blockquote className="blockquote">

                  <motion.h1 initial={{x: -300}}
                                whileInView={{x: 0}}
                                transition= {{
                                    duration: 1,
                                    delay: 0.1
                                }}
                                style={{color: "#4437B8"}}>Security and Computing Laboratory</motion.h1>
                      
                  </blockquote>
                  <figcaption className="blockquote-footer">
                      <h2 className="text-center" style={{color:"#E31B1B"}}>Jawaharlal Nehru University, New Delhi, India </h2>
                      
                  </figcaption>
                  </figure>
          </div>


        <motion.h3 
        initial={{x: -40}}
        whileInView={{x:0}}
        transition={{duration: 0.6, delay: 0.1}} className='text-center my-5' style={{color:"#4437B8"}}>About Us</motion.h3>
        

            <motion.p initial={{y: 150}}
                      whileInView={{y:0}}
                      transition={{
                        duration: 0.6,
                        delay: 0.1
                      }}
                      className="card-text" style={{textAlign:"justify"}}>
            The Security and Computing Laboratory is dedicated to advancing the fields of security and computation. Originally, the lab's research focused on foundational areas such as Wireless Sensor Networks, Trust Computation, and Cryptography. These areas provided a strong basis for understanding the complexities of securing data and communications in various environments. Over time, the lab has expanded its research scope to include more diverse and cutting-edge fields. This includes Cybersecurity, where researchers work to protect systems from digital threats, and Blockchain, which explores decentralized and secure data storage and transaction methods. The lab also delves into Post-Quantum Cryptography, preparing for a future where quantum computers could potentially break current cryptographic systems. Another significant area of focus is the application of Artificial Intelligence and Machine Learning in healthcare, particularly in enhancing security and privacy measures. The objective of the lab is not only to conduct groundbreaking research but also to foster innovation and invention. A key ethos of the lab is creating a cheerful and supportive environment, where happiness is valued and prioritized. This positive atmosphere is seen as essential for inspiring creativity and collaboration, ensuring that the lab's members thrive and contribute to making the lab a more secure and happy place.

            </motion.p>

            <motion.h1 
            initial={{x: -40}}
            whileInView={{x:0}}
            transition={{duration: 0.6, delay: 0.1}} className='text-center my-5' style={{color:"#4437B8"}}>Research Area</motion.h1>

            <div className="row my-5">
              <div className="col-sm-6 mb-3 mb-sm-0">
                <div className="card border-0 shadow-lg mb-5 rounded" style={{backgroundColor:"#FFFB72"}}>
                  <div className="card-body">
                    <motion.h2 initial={{x:-170}}
                        whileInView={{x:0}}
                        transition={{duration:0.6, delay: 0.1}} className="card-title" style={{color:"#4437B8"}}>Cyber Security</motion.h2>
                    <motion.p initial={{x:-170}}
                        whileInView={{x:0}}
                        transition={{duration:0.6, delay: 0.1}} className="card-text" style={{textAlign:"justify"}}>Cybersecurity involves protecting computer systems, networks, and data from cyber threats such as hacking, malware, and unauthorized access. Security laboratories play a crucial role in advancing cybersecurity by providing controlled environments for testing, analyzing, and improving security measures. These labs use various tools and techniques, such as vulnerability scanners, intrusion detection systems, and penetration testing software, to simulate cyberattacks and assess the resilience of different systems. By using these tools, security laboratories can identify weaknesses, develop new defense strategies, and train cybersecurity professionals to effectively respond to and mitigate real-world cyber threats.</motion.p>
                    
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card border-0 shadow-lg mb-5 rounded">
                <motion.img initial={{x:-170}}
                        whileInView={{x:0}}
                        transition={{duration:0.6, delay: 0.1}}
                        src={Cyber} className="card-img" alt="..." height="300px" width="100px">

                </motion.img>
                </div>
              </div>
            </div>


            <div className="row my-5">
              <div className="col-sm-6 mb-3 mb-sm-0">
                <div className="card border-0 shadow-lg mb-5 rounded">
                <motion.img initial={{x:170}}
                        whileInView={{x:0}}
                        transition={{duration:0.6, delay: 0.1}}
                        src={Blockchain} className="card-img" alt="..." height="325px" width="100px">

                </motion.img>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card border-0 shadow-lg mb-5 rounded" style={{backgroundColor:"#A8D8D8"}}>
                  <div className="card-body">

                  <motion.h2 initial={{x:170}}
                        whileInView={{x:0}}
                        transition={{duration:0.6, delay: 0.1}} className="card-title" style={{color:"#4437B8"}}>Blockchain</motion.h2>

                  <motion.p initial={{x:170}}
                        whileInView={{x:0}}
                        transition={{duration:0.6, delay: 0.1}} className="card-text" style={{textAlign:"justify"}}>Blockchain technology is a decentralized and distributed ledger system that ensures secure, transparent, and tamper-proof transactions across a network. In security laboratories, blockchain is studied and applied using a variety of tools to explore its potential and address its challenges. Researchers use blockchain platforms like Ethereum and Hyperledger to develop and test smart contracts, ensuring they are secure and free from vulnerabilities. Additionally, tools like Truffle and Remix are employed for blockchain development and testing, while network simulators help in analyzing blockchain performance under different scenarios. These laboratories also investigate the integration of blockchain with other technologies, like IoT and AI, to enhance security, privacy, and data integrity across various applications.</motion.p>
                  </div>
                </div>
              </div>
            </div>


            <div className="row my-5">
              <div className="col-sm-6 mb-3 mb-sm-0">
                <div className="card border-0 shadow-lg mb-5 rounded" style={{backgroundColor:"#FFD7F9"}}>
                  <div className="card-body">
                    <motion.h2 initial={{x:-170}}
                        whileInView={{x:0}}
                        transition={{duration:0.6, delay: 0.1}} className="card-title" style={{color:"#4437B8"}}>AI for Security</motion.h2>
                    <motion.p initial={{x:-170}}
                        whileInView={{x:0}}
                        transition={{duration:0.6, delay: 0.1}} className="card-text" style={{textAlign:"justify"}}>Security and computing add significant value to AI for cybersecurity by providing the foundational frameworks, algorithms, and infrastructure needed to deploy AI solutions effectively. In cybersecurity, computing power is essential for processing large datasets, running complex machine learning models, and conducting real-time analysis of network traffic and user behavior. Advanced computing resources allow AI models to learn from vast amounts of historical and real-time data, identifying patterns that can indicate threats or anomalies. Security-specific algorithms, such as those used in encryption, threat detection, and behavioral analysis, are enhanced with AI to automate threat detection and response processes, reduce false positives, and adapt to new and evolving threats. Furthermore, by integrating AI into security protocols, organizations can proactively defend against sophisticated cyberattacks, streamline incident response, and enhance overall cybersecurity posture, ensuring that systems remain robust, reliable, and resilient against potential threats.</motion.p>
                    
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card border-0 shadow-lg mb-5 rounded">
                <motion.img initial={{x:-170}}
                        whileInView={{x:0}}
                        transition={{duration:0.6, delay: 0.1}}
                        src={Ai} className="card-img" alt="..." height="388px" width="100px">

                </motion.img>
                </div>
              </div>
            </div>

            <div className="row my-5">
              <div className="col-sm-6 mb-3 mb-sm-0">
                <div className="card border-0 shadow-lg mb-5 rounded">
                <motion.img initial={{x:170}}
                        whileInView={{x:0}}
                        transition={{duration:0.6, delay: 0.1}}
                        src={Authentication} className="card-img" alt="..." height="410px" width="100px">

                </motion.img>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card border-0 shadow-lg mb-5 rounded" style={{backgroundColor:"#CCE5FF"}}>
                  <div className="card-body">

                  <motion.h2 initial={{x:170}}
                        whileInView={{x:0}}
                        transition={{duration:0.6, delay: 0.1}} className="card-title" style={{color:"#4437B8"}}>Authentication</motion.h2>

                  <motion.p initial={{x:170}}
                        whileInView={{x:0}}
                        transition={{duration:0.6, delay: 0.1}} className="card-text" style={{textAlign:"justify"}}>AI significantly enhances authentication processes by providing more secure and adaptive methods for verifying user identities. Traditional authentication methods, such as passwords and PINs, are increasingly vulnerable to theft, phishing, and other cyberattacks. AI improves upon these methods by enabling more sophisticated forms of authentication, such as biometric recognition (including facial, voice, and fingerprint recognition) and behavioral analysis (such as typing patterns or device usage). Security and computing value in AI-driven authentication lies in the ability to analyze and learn from vast datasets to identify unique user characteristics and detect anomalies in real-time, offering a more personalized and dynamic approach to verifying identities. Additionally, AI can continuously monitor user behavior to flag potential security breaches, reducing the risk of unauthorized access. By integrating AI into authentication systems, organizations can achieve stronger security measures that are both user-friendly and resilient to emerging threats, providing a robust defense against identity theft and fraud.</motion.p>
                  </div>
                </div>
              </div>
            </div>


            <div className="row my-5">
              <div className="col-sm-6 mb-3 mb-sm-0">
                <div className="card border-0 shadow-lg mb-5 rounded" style={{backgroundColor:"#FFCCFF"}}>
                  <div className="card-body">
                    <motion.h2 initial={{x:-170}}
                        whileInView={{x:0}}
                        transition={{duration:0.6, delay: 0.1}} className="card-title" style={{color:"#4437B8"}}>Trust Management</motion.h2>
                    <motion.p initial={{x:-170}}
                        whileInView={{x:0}}
                        transition={{duration:0.6, delay: 0.1}} className="card-text" style={{textAlign:"justify"}}>Trust management in cybersecurity involves establishing and maintaining reliable relationships between users, devices, and systems to ensure secure access and data exchange. It requires verifying that all entities are who they claim to be and are behaving in expected ways. With the increasing complexity and volume of digital interactions, trust management systems must be adaptive and robust. Advanced computing techniques and security frameworks provide the foundation for analyzing and monitoring these interactions in real-time. By utilizing large datasets and sophisticated algorithms, trust management systems can detect anomalies, predict potential threats, and dynamically adjust trust levels. This ensures that only trusted entities are granted access to sensitive information and resources, reducing the risk of breaches and maintaining the integrity of digital ecosystems.</motion.p>
                    
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card border-0 shadow-lg mb-5 rounded">
                <motion.img initial={{x:-170}}
                        whileInView={{x:0}}
                        transition={{duration:0.6, delay: 0.1}}
                        src={Trust} className="card-img" alt="..." height="343px" width="100px">

                </motion.img>
                </div>
              </div>
            </div>
       

        <div className="card border-0 my-4">
            <motion.img initial={{x:-170}}
                        whileInView={{x:0}}
                        transition={{duration:0.6, delay: 0.1}}
                        src={quote} className="card-img" alt="...">

            </motion.img>
            
        </div>
    </div>
  )
}

export default About