import React from 'react'
import image1 from '../images/cover.jpg'
import image2 from '../images/photo1.png'
import image3 from '../images/photo2.png'


function Slider() {
  return (

    <div className="container my-5">
        <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner rounded">
            <div className="carousel-item active" data-bs-interval="4000">
                <img src={image1} className="d-block w-100" alt="..." style={{height: "25em"}}/>
            </div>
            <div className="carousel-item" data-bs-interval="2000">
                <img src={image2} className="d-block w-100" alt="..." style={{height: "25em"}} />
            </div>
            <div className="carousel-item">
                <img src={image3} className="d-block w-100" alt="..." style={{height: "25em"}}/>
            </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
            </button>
        </div>

    </div>

    
  )
}

export default Slider