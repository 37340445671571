import React, {useState} from 'react'
// import scss from '../images/scss.jpg'
import location from '../images/location.png'
import Gmail from '../images/gmail.png'
import Telephone from '../images/telephone.png';
import emailjs from '@emailjs/browser';

function Contact() {

  const [name, setName]= useState("");
  const [email, setEmail]= useState("");
  const [message, setMessage]= useState("");

  const sendEmail=()=>{
    
    const Data={
      from_name: name,
      Email: email,
      message: message
    }

    const service_id="service_y15rwml"
    const template_id="template_vbpb9xh"
    const user_id="5LfvA4HMdXiXZIufT"

    emailjs.send(service_id,template_id,Data,user_id).
    then(
      function(response){
        alert("message send successfully");
      },
      function(error){
        console.log("error: try again later");
      }
    )
  }



  return (
    <div className="container my-5">
      <h1 className='text-center my-5' style={{color: "#4437B8"}}> <strong>Collaborate With Us</strong> </h1>

      <div className="row">
          <div className="col-sm-6 mb-3 mb-sm-0">
            <div className="card border-0">
              <div className="card-body">
              <h4 className='text-center' style={{color: "#4437B8"}}> <strong>Contact details</strong> </h4>
                
                <img src={location} alt="" height="50px" width="50px" />
                <h5 className="card-text" style={{textAlign:"justify", color:"#8a2be2"}}>Security and Computing laboratory, School of Computer and Systems Sciences, Jawaharlal Nehru University, New Delhi, India, 110067</h5>

                <img src={Gmail} alt="" height="50px" width="50px" />
                <h5 className="card-text my-4" style={{color:"#C71585"}}>karan@mail.jnu.ac.in</h5>

                <img src={Telephone} alt="" height="50px" width="50px" />

                <h5 className="card-text my-3" style={{color:"#8a2be2"}}> 011-2673-8743 </h5>


              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card shadow-lg p-3 mb-5 bg-white rounded">
              <div className="card-body">
                <h4 className='text-center my-2' style={{color: "#4437B8"}}> <strong>Send Message to us</strong> </h4>

                  <div className="input-group mb-3">
                    <div className="form-floating">
                      <input type="text" className="form-control border border border-info" id="floatingInputGroup1" placeholder="Your name" onChange={(e)=>{setName(e.target.value)}} />
                      <label htmlFor="floatingInputGroup1">Full Name</label>
                    </div>
                  </div>

                  <div className="form-floating mb-3">
                    <input type="email" className="form-control border border-info" id="floatingInput" placeholder="name@example.com" onChange={(e)=>{setEmail(e.target.value)}} />
                    <label htmlFor="floatingInput">Email address</label>
                  </div>

                  <div className="form-floating my-3">
                    <textarea className="form-control border border-info" placeholder="Leave a comment here" id="floatingTextarea2" style={{height: "100px"}} onChange={(e)=>{setMessage(e.target.value)}}></textarea>
                    <label htmlFor="floatingTextarea2">Type Your Message</label>
                  </div>

                  <div className="d-grid gap-2">
                    <button className="btn btn-custom" onClick={()=>{sendEmail()}}>Send Message</button>

                  </div>

                
              </div>
            </div>
          </div>
      </div>






      {/* <div className="card border-0 mb-3" style={{maxWidth: "1040px", margin: "0 Auto"}}>
        <div className="row g-0">
          <div className="col-md-4">
            <img src={scss} className="img-fluid rounded-start" alt="..." />
          </div>
          <div className="col-md-8">
            <div className="card-body mx-4 my-5">
              <h2 className="card-title" style={{color:"#8a2be2"}}>Security and Computing Labrotory</h2>
              <h4 className="card-text my-5" style={{color:"#C71585"}}>School of Computer and Systems Sciences, Jawaharlal Nehru University, New Delhi, India, 110067</h4>
              <h5 className="card-text my-4"> <span style={{color:"#8a2be2"}}>Email:-</span> <span style={{color:"#C71585"}}>karan@mail.jnu.ac.in</span>  </h5>

              <h5 className="card-text my-3"> <span style={{color:"#8a2be2"}}>Phone:-</span> <span style={{color:"#C71585"}}>011-2673-8743</span>  </h5>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default Contact