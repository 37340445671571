import React from 'react'
import mtech from '../data_json/mtech.json'
import { Link } from 'react-router-dom';


function Mtech() {

  return (
    <div className="container my-5">

     <h1 className="text-center" style={{color:"#4437B8"}}>M. Tech</h1>

    {/* <div className="p-3 mb-2 bg-dark bg-gradient text-white my-5"><h3>Current Students</h3></div> */}

    <div className="my-4">
      <div className="row row-cols-1 row-cols-md-3 g-4">

        {mtech.MTECH && mtech.MTECH.map((e,index)=>(

          <div className="col">
          <div className="card h-80 shadow p-3 mb-5 bg-white rounded">
          <img key={index} src={e.image} className="card-img-top rounded-circle mx-auto d-block my-3" alt="..." style={{height:"50%", width:"50%",borderRadius:"50%"}} />
            <div className="card-body">
              <h5 className="card-title text-center">{e.Name}</h5>
              <p className="card-text text-center">{e.batch}</p>
              <div class="d-grid gap-2">
                <Link to={`/mtechcard/${"MTECH"}/${e.id}`} class="btn btn-custom" type="button">View more</Link>
                
              </div>
            </div>
          </div>
          </div>


        ))}
        
      </div>

      </div>

      {/* <div className="p-3 mb-2 bg-dark bg-gradient text-white"><h3>Alumni</h3></div> */}

      <div className="my-4">
      <div className="row row-cols-1 row-cols-md-3 g-4">

        {mtech.MTECHALUMNI && mtech.MTECHALUMNI.map((e,index)=>(

          <div className="col">
          <div className="card h-100">
          <img key={index} src={e.image} className="card-img-top rounded-circle mx-auto d-block my-3" alt="..." style={{height:"50%", width:"50%",borderRadius:"50%"}} />
            <div className="card-body">
              <h5 className="card-title text-center">{e.Name}</h5>
              <p className="card-text text-center">{e.batch}</p>
              <div class="d-grid gap-2">
                <Link to={`/mtechalumni/${"MTECH"}/${e.id}`} class="btn btn-custom" type="button">View more</Link>
                
              </div>
            </div>
          </div>
          </div>


        ))}
        
      </div>

      </div>


    </div>
  )
}

export default Mtech