import React from 'react'
import mca from '../data_json/mca.json'
import { Link } from 'react-router-dom';


function Mca() {

  return (
    <div className="container my-5">

     <h1 className="text-center" style={{color:"#4437B8"}}>MCA</h1>

    <div className="p-3 mb-2 bg-dark bg-gradient text-white my-5"><h3>Current Students</h3></div>

    <div className="my-4">
      <div className="row row-cols-1 row-cols-md-3 g-4">

        {mca.MCA && mca.MCA.map((e,index)=>(

          <div className="col">
          <div className="card h-100">
          <img key={index} src={e.image} className="card-img-top rounded-circle mx-auto d-block my-3" alt="..." style={{height:"50%", width:"50%",borderRadius:"50%"}} />
            <div className="card-body">
              <h5 className="card-title text-center">{e.Name}</h5>
              <p className="card-text text-center">{e.batch}</p>
              <div class="d-grid gap-2">
                <Link to={`/mcacard/${"MCA"}/${e.id}`} class="btn btn-custom" type="button">View more</Link>
                
              </div>
            </div>
          </div>
          </div>


        ))}
        
      </div>

      </div>

      <div className="p-3 mb-2 bg-dark bg-gradient text-white"><h3>Alumni</h3></div>

      <div className="my-4">
      <div className="row row-cols-1 row-cols-md-3 g-4">

        {mca.ALUMNI && mca.ALUMNI.map((e,index)=>(

          <div className="col">
          <div className="card h-100">
          <img key={index} src={e.image} className="card-img-top rounded-circle mx-auto d-block my-3" alt="..." style={{height:"50%", width:"50%",borderRadius:"50%"}} />
            <div className="card-body">
              <h5 className="card-title text-center">{e.Name}</h5>
              <p className="card-text text-center">{e.batch}</p>
              <div class="d-grid gap-2">
                <Link to={`/mtechcard/${"MTECH"}/${e.id}`} class="btn btn-custom" type="button">View more</Link>
                
              </div>
            </div>
          </div>
          </div>


        ))}
        
      </div>

      </div>


    </div>

  )
}
export default Mca;