import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home/Home';
import Slider from './components/Slider/Slider';
import About from './components/About/About';

import Footer from './components/Footer/Footer';
import Footer_copy from './components/Footer/Footer_copy';

import Mtech from './components/Member/Mtech';
import Phd from './components/Member/Phd';
import MCA from './components/Member/Mca'
import Publications from './components/publications/Publications';
import Contacts from './components/Contacts/Contact';
import Phdcard from './components/Member/Phdcard';
import Recentevent from './components/RecentEvent/Recentevent';
import PhdAlumini from './components/Member/PhdAlumini';
import MtechAlumni from './components/Member/Mtechalumni';
import UpcomingEvent from './components/Events/UpcomingEvent';
import Recentevents from './components/Events/Recentevents';
import Mtechcard from './components/Member/Mtechcard';
import Mcacard from './components/Member/Mcacard';
import Submission from './components/Submission/Submission';
import Important from './components/Important_material/Important';
import Material from './components/Important_material/Material_link';

import Karansir from './components/Member/Karansir';
import Team from './components/Member/Team';
import Funding from './components/Funding/Funding';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Material_link from './components/Important_material/Material_link';

function App() {
  return (
    <BrowserRouter>
     <Navbar/>
     <Slider />

    

     <Routes>

     <Route path="/" element={<><About /></>} />
     
     <Route path="mtech" element={<Mtech/>} />
     <Route path="phd" element={<Phd/>} />
     <Route path="publications" element={<Publications/>} />
     <Route path="contacts" element={<Contacts/>} />
     <Route path="sponsorship" element={<Funding/>} />

     <Route path="Video_lecture" element={<Important/>} />
     <Route path="Student_corner" element={<Material_link/>} />


     <Route path="upcomingevent" element={<UpcomingEvent/>} />
     <Route path="recentevent" element={<Recentevents/>} />
     {/* <Route path="Submission" element={<Submission/>} /> */}

     <Route path="Mentor" element={<Karansir/>} />
     <Route path="Teams" element={<Team/>} />

     <Route path="mca" element={<MCA/>} />

     <Route path="phdcard/:course/:id" element={ <Phdcard/> } />
     <Route path="mtechcard/:course/:id" element={ <Mtechcard/> } />
     <Route path="mcacard/:course/:id" element={ <Mcacard/> } />

     <Route path="phdalumini/:course/:id" element={ <PhdAlumini/> } />
     <Route path="mtechalumni/:course/:id" element={ <MtechAlumni/> } />
     {/* <Route path="phdalumini/:course/:id" element={ <PhdAlumini/> } /> */}

     

     

     </Routes>
     


     <Footer />
     <Footer_copy />
    </BrowserRouter>
  );
}

export default App;
