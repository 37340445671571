import React from 'react'
import {Outlet ,Link } from 'react-router-dom'
import './Navbar.css'
import scl from '../components/images/scl.png'

function Navbar() {
  return (
    <>
    <nav className="navbar sticky-top navbar-expand-lg bg-body-tertiary shadow" >
  <div className="container-fluid">

  <a className="navbar-brand mx-3" href="/" style={{color:"#4437B8", display:"flex"}}> 
    <img src={scl} alt="Logo" width="100" height="100" className="d-inline-block align-text-top" />
    <h4 className="my-3"> <strong> Security and <br/> Computing <br/>Laboratory </strong></h4> 
  </a> 

    {/* <a className="navbar-brand mx-3" href="/" style={{color:"#4437B8"}}> <h4> <strong> Security and Computing <br/> Laboratory</strong></h4></a> */}
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0 mx-4">

        <li className="nav-item mx-3">
          <Link to="/" className="btn btn-outline-custom" aria-current="page">Home</Link>
        </li>

        <li className="nav-item mx-3">
          <Link to="/Teams" className="btn btn-outline-custom" aria-current="page">Team</Link> 
        </li>

        <li className="nav-item mx-3">
          <Link to="/sponsorship" className="btn btn-outline-custom" aria-current="page">Funding</Link>
        </li>

        {/* <li className="nav-item mx-3 dropdown">
          <a className="nav-link dropdown-toggle btn-custom" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Members
          </a>
          <ul className="dropdown-menu">
          <li><Link to="/Mentor" className="dropdown-item btn-custom">Mentor</Link></li>
          <li><hr className="dropdown-divider" /></li>
            <li><Link to="/phd" className="dropdown-item btn-custom">Ph.D</Link></li>
            <li><hr className="dropdown-divider" /></li>
            <li><Link to="/mtech" className="dropdown-item btn-custom">M. Tech</Link></li>
            <li><hr className="dropdown-divider" /></li>
            <li><Link to="/mca" className="dropdown-item btn-custom">MCA</Link></li>
            <li><hr className="dropdown-divider" /></li>
            <li><Link to="/mtech" className="dropdown-item btn-custom">Intern</Link></li>
          </ul>
        </li> */}

        <li className="nav-item mx-3">
          <Link to="/publications" className="btn btn-outline-custom">Publications</Link>
        </li>
        <li className="nav-item mx-4 dropdown">
          <a className="nav-link dropdown-toggle btn-custom" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Events
          </a>
          <ul className="dropdown-menu">
            <li><Link to="/upcomingevent" className="dropdown-item btn-custom">Upcoming events</Link></li>
            <li><hr className="dropdown-divider" /></li>
            <li><Link to="/recentevent" className="dropdown-item btn-custom">Recent events</Link></li>
            {/* <li><hr className="dropdown-divider" /></li>
            <li><a className="dropdown-item btn-custom" href="/">Gallary</a></li>
            <li><hr className="dropdown-divider" /></li>
            <li><a className="dropdown-item btn-custom" href="/">Inveited talk</a></li> */}
          </ul>
        </li>

        {/* <li className="nav-item mx-3">
          <Link to="/Submission" className="btn btn-outline-custom" aria-disabled="true">Submission Process</Link>
        </li> */}

        <li className="nav-item mx-3">
          <Link to="/Student_corner" className="btn btn-outline-custom" aria-disabled="true">Student Corner</Link>
        </li>


        <li className="nav-item mx-3">
          <Link to="/contacts" className="btn btn-outline-custom" aria-disabled="true">Collaborate With Us</Link>
        </li>

        


      </ul>
      
    </div>
  </div>
</nav>

<Outlet />
</>
  )
}

export default Navbar