import {React, useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'

import data from '../data_json/mca.json'

function Mcacard() {

    const [data1, setData1]=useState(null)
    const [loading, setLoading]= useState(true);
  
    const {course, id} =useParams();
    
  
    useEffect(()=>{
  
      for(let record of data.MCA ){
        if(record.id==id){
          setData1(record);
          setLoading(false);
        }
      }
  
  
    },[course. id])

    


  return (
    <div className="container my-5">

    {loading ? (
      <div className="d-flex justify-content-center">
        <div className="spinner-grow text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
     </div>
    ):(

      <div className="card border-0 mb-3 " style={{maxWidth: "940px", margin: "0 auto"}}>
      <div className="row g-0">
        <div className="col-md-4">
          <img src={data1.image} className="img-fluid rounded" alt="..." />
        </div>
        <div className="col-md-8">
          <div className="card-body mx-5">
            <h1 className="card-title my-3">{data1.Name}</h1>

            <p className="card-text"><strong>Course: </strong>MCA</p>
            <p className="card-text"><strong>Batch: </strong>{data1.batch}</p>
            <p className="card-text"><strong>Email: </strong>{data1.Email}</p>
            <p className="card-text"><strong>Project: </strong>{data1.Project}</p>

            
            
          </div>
        </div>
      </div>
    </div>
    )}


  </div>

    )
}

export default Mcacard;