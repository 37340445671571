import React from 'react'
import karan_sir from '../images/karan_sir.png'

function Karansir() {
  return (
    <div className="container my-5">

        <div className="card border-0 text-center" style={{maxWidth: "940px",margin: "0 auto"}}>
        <img src={karan_sir} className="card-img-top rounded-circle mx-auto d-block my-3" alt="..." style={{height:"30%", width:"30%",borderRadius:"50%"}} />
            <div className="card-body">
                <h3 className="card-title" style={{color:"#4437B8"}}>Dr. Karan Singh</h3>
                <h4 className="card-text" style={{color:"#C71585"}} >API Score 2000+</h4>
                <h4 className="card-text" style={{color:"#C71585"}} >Senior Member IEEE, IEEE MGM Awardee</h4>
                <h5 className="card-text" style={{color:"#8a2be2"}} >Assistant Professor, SCSS, JNU, New Delhi, India</h5>

                <h5 className="card-text" style={{color:"#8a2be2"}} >Email:- karan@mail.jnu.ac.in </h5>
                <h5 className="card-text" style={{color:"#8a2be2"}} >Phone:- 09971037951</h5>
                
            </div>
        </div>

        <h4 className="card-text my-3" style={{color:"#4437B8"}}>About Mentor</h4>

        <p className="card-text" style={{textAlign:"justify"}}>KARAN SINGH (SMIEEE) received the Engineering degree (Computer Science &amp; Engineering) from Kamala Nehru Institute of Technology, Sultanpur, UP, India, and the M.Tech(Computer Science &amp; Engineering) from Motilal Nehru National Institute of Technology UP, India. He is Ph.D. (Computer Science &amp; Engineering) from MNNIT Allahabad, UP, India. He worked at Gautam Buddha University from January 2010 to January 2014. Currently, he is working with the School of Computer &amp; Systems Sciences, Jawaharlal Nehru University, New Delhi. He works as a proctor and Recruitment committee member at Gautam Buddha University. He is a member of the Admission and placement committee at the school level. His primary research interests are in cyber security, computer network security, multicast communication Internet of things, Authentication, Trust, Blockchain, AI, and WSN. He is a reviewer of IEEE and; Elsevier conferences and a reviewer of International Journals and; IEEE Transactions. He is an Editorial Board Member of the Journal of Communications and Network (CN), USA. He supervised 20 Ph.D., and 56 M.Tech. students. He published 100+ research papers in the journal SCI/SCIE/Scopus and a good conference. He organized various workshops, sessions, conferences, and training.</p>

        


       {/* <div className="card border-0 mb-3 my-5"  style={{maxWidth: "1240px", margin: "0 auto"}}>
            <div className="row g-0">
                <div className="col-md-4">
                <img src={karan_sir } className="img-fluid rounded-start" alt="..." />
                </div>
                <div className="col-md-8">
                <div className="card-body">
                    <h3 className="card-title" style={{color:"#4437B8"}}>Dr. Karan Singh</h3>
                    <h5 className="card-text" style={{color:"#C71585"}} >Assistant Professor, SCSS, JNU, New Delhi, India</h5>
                    <p className="card-text" style={{textAlign:"justify"}}>KARAN SINGH (SMIEEE) received the Engineering degree (Computer Science &amp; Engineering) from Kamala Nehru Institute of Technology, Sultanpur, UP, India, and the M.Tech(Computer Science &amp; Engineering) from Motilal Nehru National Institute of Technology UP, India. He is Ph.D. (Computer Science &amp; Engineering) from MNNIT Allahabad, UP, India. He worked at Gautam Buddha University from January 2010 to January 2014. Currently, he is working with the School of Computer &amp; Systems Sciences, Jawaharlal Nehru University, New Delhi. He works as a proctor and Recruitment committee member at Gautam Buddha University. He is a member of the Admission and placement committee at the school level. His primary research interests are in cyber security, computer network security, multicast communication Internet of things, Authentication, Trust, Blockchain, AI, and WSN. He is a reviewer of IEEE and; Elsevier conferences and a reviewer of International Journals and; IEEE Transactions. He is an Editorial Board Member of the Journal of Communications and Network (CN), USA. He supervised 20 Ph.D., and 56 M.Tech. students. He published 100+ research papers in the journal SCI/SCIE/Scopus and a good conference. He organized various workshops, sessions, conferences, and training.</p>
                </div>
                </div>
            </div>
        </div> */}

        <h4 className="card-text my-3" style={{color:"#4437B8"}}>Subject Taught (PG and UG)</h4>

        <p className="card-text">Network Security, Cyber Security, Web Based Programming, C Programming, Information Security, Internet and Web Technology, Multicast Communication, Networking Protocols, Security in Wireless Network, Information Security, Java Programming and respective laboratory.</p>

        <h4 className="card-text my-3" style={{color:"#4437B8"}}>Research Interest</h4>

        <p className="card-text">Cyber Security, Blockchain, Trust and Authentication in Wireless Sensor Network, Intelligent Vehicular Network, Multicast Communication, Internet of Things, and Cyber Physical System</p>

        <h4 className="card-text my-3" style={{color:"#4437B8"}}>Professional Membership</h4>
        <ul>

        <li>Professional member Association for Computing Machinery (ACM), New York</li>
            <li>the Computer Science  Teachers Association (CSTA), U.S.A</li>
            <li>the Computer Society of India(CSI), Secunderabad, India,</li>
            <li> Cryptology Research Society of India (CRSI), Kolkata, India</li>
            <li>Institute of Electrical and Electronics Engineers (IEEE), USA</li>
            <li>International Association of Computer Science and Information Technology (IACSIT), Singapore</li>
            <li>Institute for Computer Sciences, Social-Informatics and  Telecommunications Engineering (ICST), America</li>
            <li>International Association of Engineers (IAENG), Hong Kong</li>
            <li>Association of Computer Electronics and Electrical Engineers (ACEEE), India</li>
            <li> Internet Society(ISOC), USA</li>
            <li>Academy & Industry Research Collaboration Center (AIRCC), India</li>
        
            
        </ul>

        
        <h4 className="card-text my-3" style={{color:"#4437B8"}}>Research ID's</h4>
        <ul>
            <li>IEEE Membership ID: #80615435</li>
            <li>ACM Membership ID: #1555164</li>
            <li>Orcid ID: #0000-0002-6992-1655</li>
            
        </ul>

        <h4 className="my-3 card-text" style={{color:"#4437B8"}}>Conferences General Chair</h4>

        <ul>
            <li>General Chair of 9th International Conference Qshine, Greater Noida, India, January 2013,</li>
            <li>Chair of International Conference ICCCS 2016 at JNU, New Delhi</li>
            <li>Chair of International Conference NetCrypt 2019 at JNU, New Delhi</li>
            <li>Chair of International Conference ICCCS 202018 at JNU, New Delhi</li>
            <li>Chair of International Conference NetCrypt 2020 at JNU, New Delhi</li>
        </ul>

        <h4 className="my-3 card-text" style={{color:"#4437B8"}}>Event Organized</h4>

        <ul>
            <li>International Conference on Intelligent Computing and Communication Techniques, (ICICCT), 2024</li>
            <li>ATAL FDP organized 2019, 2020 and 2021.</li>
            <li>International Conference ICCCS 2018, Cyber Security 2017, Python 2016</li>
            <li>International Conference ICCCS 2016, Open Source Technology 2015</li>
            <li>International Conference (Qshine-2013), Computer Networking Equipment
            2012</li>
            <li>Wireless Communication 2011, Networking Tool - QUALNET 2011</li>
            <li>Database Management Technology 2010</li>
        </ul>

        <h4 className="my-3 card-text" style={{color:"#4437B8"}}>TCP Member and Session Chair</h4>

        <ul>
            <li>3rd International Conference on Automation & Computation (Autocom), 2022</li>
            <li>International Conference on System Modelling & Advancement on Research Trends (SMART), 2021</li>
            <li>International Conference on Network and Cryptology (Netcrypt), 2020</li>
            <li>5th International Conference on Computer and Communication Systems (ICCCS), 2020</li>
            <li>IEEE International Conference on Computational Science and Engineering, CSE (CCSE), 2020, 2019, 2021, 2022</li>
            <li>3rd International Conference on Computer and Communication Systems (ICCCS) 2018</li>
            <li>4th Annual International Conference on Recent Trends in Computer Science and Electronics (RTCSE) USA, 2019</li>
            <li>2nd EAI International Conference on Smart Grid and Internet of Things (SGIoT), 2018 </li>
            <li>1st EAI International Conference on Smart Grid Assisted Internet of Things (SGIoT), Canada, 2017</li>
            <li>2nd International Conference on Green Computing and Engineering Technologies (ICGCET), Denmark, 2016</li>
            <li>National Conference at AKGEC, India 2016</li>
            <li>IEEE International Conference on Computing, Communication & Automation, India 2015</li>
            <li>2nd International Conference on Advances in Computing and Communication Engineering (ICACCE), India, 2015</li>

            <li>International Workshop on Cloud computing 2014</li>
            <li>International Conference on Advances in Computing, Communications and Informatics (ICACCI), India 2014</li>
            <li>1st International Conference on Artificial intelligence and control engineering (ICAICE), India, 2015</li>
            <li>Computer Society of India (CSI) Convention, India, 2015</li>
            <li>International Conference on Computing For Sustainable Global Development (Indiacom), New Delhi 2014</li>
            <li>Editorial Board Member of Journal of Communications and Network (CN), USA</li>
            <li>IEEE International Workshop on Time Critical Applications (IWTCA' 09), ICUMT 2009, Russia</li>
        </ul>

        <h4 className="my-3 card-text" style={{color:"#4437B8"}}>AWARDS</h4>

        <ul>
            <li>Young Scientist award, 2nd International Conference on Green Computing and Engineering Technologies (ICGCET), Aalborg University, Denmark 2016</li>
            <li>Best Academic Researcher, by Association of Scientists, Developers and Faculties Awards, Pondicherry, India, 2012</li>
            <li>Biography nominated to publish in “Marquis Who’s Who in the World” Berkeley Heights, NJ, 2008.</li>
        </ul>

        <h4 className="my-3 card-text" style={{color:"#4437B8"}}>Authorship/ Editorship</h4>

        <ul>
            <li> AAP Research Notes on Optimization and Decision Making Theories, Recent Trends in AI, Machine Learning, and Network Security, Forthcoming January 2023, ISBN: 9781774912393</li>
            <li> Journal Guest Editor: JITM, 2022</li>
            <li>Journal Guest Editor: JITM, 2021</li>
            <li> Journal Guest Editor: Taylor & Francis, 2020 (JDMSC)</li>
            <li>Journal Guest Editor: Taylor & Francis 2019 (JDMSC)</li>
            <li>Springer Edited Book: Network and Cryptology” Publication Springer ISBN XXXX Edition 2019</li>

            <li>Taylor & Francis CRC Edited Book: Communication and Computing Systems: Proceedings of the International Conference on Communication and Computing Systems (ICCCS 2018), Gurgaon, India, 1-2 December, 2018.</li>
            <li>Journal Guest Editor: MONET Springer, 2017 (SGIoT).</li>
            <li>Taylor & Francis CRC Edited Book: Communication and Computing Systems: Proceedings of the International Conference on Communication and Computing Systems (ICCCS 2018), Gurgaon, India, April 27-30, 2018</li>
            <li>Taylor & Francis CRC Edited Book: Communication and Computing Systems: Proceedings of the International Conference on Communication and Computing Systems (ICCCS 2016), Gurgaon, India, September 9-11, 2016</li>
            <li>Springer Edited Book: QUALITY, RELIABILITY, SECURITY AND ROBUSTNESS IN HETEROGENEOUS NETWORKS” Publication Springer Lecture Notes of the Institute for Computer Sciences, Social Informatics and Telecommunications Engineering, Vol. 115, PP 1-1007, ISBN: 978-3-642-37948-2 Edition 2013.</li>
        </ul>

        <h4 className="my-3 card-text" style={{color:"#4437B8"}}>Invited Talk</h4>

        <ul>
            <li>Deliver a talk on “Trust Management using ML” during FDP at AKGEC Ghaziabad, UP India, August 9, 2024</li>
            <li>Deliver a talk on “Recent Technology in Machine Learning and Security ” during FDP at JIMS Greator Noida, UP India, August 1, 2024</li>
        </ul>

        <h4 className="my-3 card-text" style={{color:"#4437B8"}}>Administrative Activities </h4>

        <p className="card-text" style={{textAlign:"justify"}}>Board of Study Member of GHEU, UK, Board of Study Member of Sharda University, UP, Board of Study Member of KNIT Sultanpur UP, India, Board of study member of Rafael University, Gurgaoan, India, Board of Study Member of Arunachal University of Studies, Namsai, Arunachal Pradesh, Placement Coordinator SC &SS, JNU, MCA Review Committee Member, JNU, 2015, Admission Committee Member, JNU, 2015, M.Tech. Coordinator, GBU, 2012, Procter GBU, 2011, Faculty in-Charge Computer Network Laboratory, 2012, Faculty Coordinator University and School level, 2012</p>

        <h4 className="my-3 card-text" style={{color:"#4437B8"}}>Reviewed Articles</h4>

        <p className="card-text" style={{textAlign:"justify"}}>IEEE TIIS, Elsevier IJCA, Multimedia Tools, IEEE Internet of Things, IEEE Sensor, AIHC, IEEE ACESS, Journal of Network Security, IEEE Transactions on Industry Applications, Journal ITSSA, Wireless Personal Communications, IJSDA, Scientia Iranica, Journal of Communications and Network, MONET, IGI-Global IJISP, Defense Science Journal, Hindawi journal of Security and Communication Networks, Journal of Computer Networks and Communication, Journal of Optimization, Mathematical Problems in Engineering, International Journal of Computer Science and Information Security, IJPE</p>

        <h4 className="my-3 card-text" style={{color:"#4437B8"}}>Conference Reviewer</h4>

        <p className="card-text" style={{textAlign:"justify"}}>SMART 2020, 2021, 2022, IEEE CCSE, 2018, 2019, 2021, 2022, ICSSS 2016, ICCCS 2018, IndiaCom 2018, ICCCA2017, IndiaCom 2017, ICCCA2016, IndiaCom 2016, ICCCA2015, IndiaCom 2015, IndiaCom 2014, ISBEIA 2013, SHUSER 2012, ICIAICT 2012, International Conference on Computer, Communication, Control and Information Technology-2012, COMMUNE CACCS 2012, of Academy & Industry Research Collaboration Center (AIRCC), 2011, ICUMT 2009</p>

        <h4 className="my-3 card-text" style={{color:"#4437B8"}}>Delivered Lectures</h4>

        <p className="card-text" style={{textAlign:"justify"}}>CHU 2021, GHEU 2022, AMU, Aligarh, 2021, NIT Allahabad 2020, 2021, 2022 GBAUT 2020, Pantnagar, TMU, Moradabad, India 2018, HSET SRHU, Dehradun 2018, I.I.M.T, , Greater Noida, India, Deewan Engineering Collage Meerut, India, 2018, Shri Ram Murti Smarak College of Engineering & Technology, Bareilly, India, 2017, GHEU, Dehradun 2016, I.T.S Engineering College, Greater Noida, India, 2016, SRM University, Ghaziabad, 20017, IFTM, Moradabad 2015, AKGEC, Ghaziabad 2015, I.T.S Engineering College, Greater Noida 2015, K. R. Mangalam University, 2015, IFTM Moradabad 2014, Gautam Buddha University, Greater Noida, 2011, Motilal Nehru National Institute of Technology Allahabad, India 2008.</p>



       


    </div>
  )
}

export default Karansir