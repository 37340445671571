import React from 'react'

function Important() {

  const vidoe_sessions=[
    {
        Course_Name:"Cyber Security",
        Description:"The Cyber Security course on NPTEL provides a comprehensive introduction to the principles and practices of safeguarding digital systems and networks. It covers essential topics such as cryptography, network security, web security, and operating system security, as well as incident response and ethical hacking. Participants will learn to identify and mitigate various cyber threats, implement robust security measures, and conduct risk assessments. Designed for students, IT professionals, and anyone interested in cybersecurity, this course equips learners with the skills needed to protect against cyber attacks in an increasingly digital world. A basic understanding of computer networks is recommended for enrollees.",
        Video_link:"https://www.youtube.com/embed/videoseries?si=12pEe4pnkSsFJI6k&amp;list=PLyqSpQzTE6M-jkJEzbS5oHJUp2GWPsq6e"
    },

    {
        Course_Name:"Network Security",
        Description:"The Network Security course on NPTEL offers a detailed exploration of the techniques and strategies required to protect computer networks from unauthorized access and cyber threats. The course covers key topics such as network architectures, firewalls, intrusion detection and prevention systems, VPNs, and secure network protocols. Participants will learn to analyze network vulnerabilities, implement security measures, and develop strategies to safeguard network infrastructures. This course is ideal for students, network administrators, and IT professionals seeking to enhance their understanding of network security. A foundational knowledge of computer networks is recommended for those enrolling in the course.",
        Video_link:"https://www.youtube.com/embed/videoseries?si=e9Z8k2O2r2l9RZmZ&amp;list=PLJ5C_6qdAvBFAuGoLC2wFGruY_E2gYtev"
    },

    {
        Course_Name:"Web Technology",
        Description:"The Web Technology course on NPTEL provides a comprehensive overview of the technologies and tools used in building and managing modern web applications. It covers essential topics such as HTML, CSS, JavaScript, web frameworks, server-side programming, and web services. Participants will gain hands-on experience in designing responsive web pages, developing interactive features, and integrating front-end and back-end technologies. This course is ideal for students, aspiring web developers, and IT professionals seeking to deepen their knowledge of web development and deployment. A basic understanding of programming and the internet is recommended for those enrolling in the course.",
        Video_link:"https://www.youtube.com/embed/h_RftxdJTzs?si=UA_T7K6cXIQjrGX_"
    }
  ] 
  return (
    <div className="container my-5">
        <h1 className="card-text text-center" style={{color:"#4437B8"}}>Important Vidoes and Sessions</h1>

        <div class="row row-cols-1 row-cols-md-2 g-4 my-5">

        {vidoe_sessions.map((e,index)=>(
        
        <div class="col">
          <div class="card">
          <iframe height="415" src={e.Video_link} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; modestbranding; showinfo=0; fullscreen"></iframe>
            <div class="card-body">
              <h5 class="card-title">{e.Course_Name}</h5>
              <p class="card-text" style={{textAlign:'justify'}}>{e.Description}</p>
            </div>
          </div>
        </div>
        ))}

        </div>


    </div>
  )
}

export default Important