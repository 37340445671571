import { color } from 'framer-motion'
import React from 'react'
import Recommendation from './RECOMMENDATION_FORM.docx'
import Student_info from './Student_Information.docx'
import Annexure from './Annexure.docx'
import Sanjib_recomm from './0_Sanjib_RECOMMENDATION_FORM.pdf'
import Sanjib_info from './8_Sanjib_Student_Information.pdf'
import Annexure3 from './9_Sanjib_Annexure_3.pdf'

function Submission() {
  return (
    <div className="container my-5">
        <h1 className='text-center' style={{color: "#4437B8"}}> <strong>M. Tech Dissertation and Ph.D Thesis Submission Process</strong> </h1>

        <figcaption className="blockquote-footer text-center my-2" style={{color:"orangered"}}>
          For Jawaharlal Nehru University (JNU) <cite title="Source Title">Students Only</cite>
        </figcaption>

        <div className="container my-5 mx-2">
            <p className="h5 my-4" style={{textAlign:"justify"}}><strong><span style={{color: "#4437B8"}}>Step I:</span></strong> Complete your No due</p>

            <p className="h5 my-4" style={{textAlign:"justify"}}><strong><span style={{color: "#4437B8"}}>Step II:</span> </strong> Generate the TSF form</p>

            <p className="h5 my-4" style={{textAlign:"justify"}}><strong><span style={{color: "#4437B8"}}>Step III:</span> </strong> Pay the Submission Fee via the cashless portal <a href="https://jnucashless.fdsbase.com/" className="btn btn-custom mx-2" target="_blank">Pay fee</a></p>

            <p className="h5 my-4" style={{textAlign:"justify"}}><strong><span style={{color: "#4437B8"}}>Step IV:</span> </strong> Correct the thesis as per suggestions</p>

            <p className="h5 my-4" style={{textAlign:"justify"}}><strong><span style={{color: "#4437B8"}}>Step V:</span></strong> Need to prepare documents are as follows:</p>

        </div>

        <div className="container my-5">
            <p className="h5" style={{color: "#4437B8", textAlign: "justify"}}><strong> Organize these documents by name. <span style={{color:"orangered"}}> For example, if a student's name is Sanjib, </span> all the documents with that name should be grouped together as follows.</strong></p>

            <ul className="my-4">
                <li className="my-3"> <strong>0_Sanjib_RecommendationForm</strong> <a href={Recommendation} className="btn btn-custom mx-2 my-2" download="Recommendation">Download Form</a> <a href={Sanjib_recomm} type="button" className="btn btn-outline-danger mx-2 my-2" download="0_Sanjib_RECOMMENDATION">Example</a></li>

                <li className="my-3"> <strong>1_Sanjib_Dissertation</strong></li>

                <li className="my-3"> <strong>2_Sanjib_PlagReport</strong></li>

                <li className="my-3"> <strong>3_Sanjib_TSF</strong><button type="button" className="btn btn-outline-danger mx-2">Example</button></li>

                <li className="my-3"> <strong>4_Sanjib_M.Tech/Ph.D_Marksheet</strong></li>

                <li className="my-3"> <strong>5_Sanjib_M.TechSubmissionFeeReceipt</strong><a href="https://jnucashless.fdsbase.com/" className="btn btn-custom mx-2 my-2" target="_blank">Pay fee</a></li>

                <li className="my-3"> <strong>6_Sanjib_CurrentSemesterFolio</strong></li>

                <li className="my-3"> <strong>7_Sanjib_Declaration(Information)</strong> <a href={Student_info} className="btn btn-custom mx-2 my-2" download="Student_information">Download Form</a> <a href={Sanjib_info} type="button" className="btn btn-outline-danger mx-2 my-2" download="8_Sanjib_Student_Information">Example</a></li>

                <li className="my-3"> <strong>8_Sanjib_NoDues</strong> <a href="https://ttsreview.jnu.ac.in/nodues/school/NoDues_view_internet.php" className="btn btn-custom mx-2" target="_blank">Take the Screen Shot</a></li>

                <li className="my-3"> <strong>9_Sanjib_Annexure3</strong> <a href={Annexure} className="btn btn-custom mx-2 my-2" download="Annexure">Download Form</a> <a href={Annexure3} type="button" className="btn btn-outline-danger mx-2 my-2" download="9_Sanjib_Annexure_3">Example</a></li>

                <li className="my-3"> <strong>10_Sanjib_ConsentForm</strong> <a href="" className="btn btn-custom mx-2 my-2">Download Form</a> <button type="button" className="btn btn-outline-danger mx-2 my-2">Example</button></li>

                <li className="my-3"> <strong>11_Sanjib_Signature</strong></li>

                <li className="my-3"> <strong>12_Sanjib_Abstract</strong></li>

                <li className="my-3"> <strong>13_Sanjib_Signature</strong></li>
            </ul>
        </div>

        
    </div>
  )
}

export default Submission