import {React, useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import data from '../data_json/phd.json';


function PhdAlumini() {

  const [data1, setData1]=useState(null)
  const [loading, setLoading]= useState(true);

  const {course, id} =useParams();
  console.log(course, id)

  useEffect(()=>{

    for(let record of data.PHDALUMNI ){
      if(record.id==id){
        setData1(record);
        setLoading(false);
        console.log(data1);
      }
    }


  },[course. id])

  console.log(data1);

  
  return (
    <div className="container my-5">

    {loading ? (
      <div className="d-flex justify-content-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
    ):(

      <div class="card border-0 mb-3 " style={{maxWidth: "940px", margin: "0 auto"}}>
      <div class="row g-0">
        <div class="col-md-4">
          <img src={data1.image} class="img-fluid rounded" alt="..." />
        </div>
        <div class="col-md-8">
          <div class="card-body mx-5">
            <h1 class="card-title my-3">{data1.Name}</h1>

            <p className="card-text"><strong>Course: </strong>PhD</p>
            {/* <p className="card-text"><strong>Batch: </strong>{data1.batch}</p> */}
            <p className="card-text"><strong>Email: </strong>{data1.Email}</p>
            {/* <p className="card-text"><strong>Phone: </strong>{data1.Phone}</p> */}
            <p className="card-text" style={{textAlign:"justify"}}><strong>About: </strong>{data1.About}</p>

            
            
          </div>
        </div>
      </div>

      <ul className="my-2">
            {data1.publications_journal.length? <h4 className="my-3 card-text" style={{color:"#4437B8"}}>Journals</h4> : "" }

            

            {data1.publications_journal.map((e,index)=>(

              <li style={{textAlign: 'justify'}}>{e}</li>

            ))}

        </ul>

        <ul className="my-2">

        {data1.publications_conference.length? <h4 className="my-3 card-text" style={{color:"#4437B8"}}>Conferences</h4> : "" }

            {data1.publications_conference.map((e,index)=>(

              <li style={{textAlign: 'justify'}}>{e}</li>

            ))}

        </ul>

        <ul className="my-2">

        {data1.Book_chapter.length? <h4 className="my-3 card-text" style={{color:"#4437B8"}}>Book Chapter</h4> : "" }

            {data1.Book_chapter.map((e,index)=>(

              <li style={{textAlign: 'justify'}}>{e}</li>

            ))}

        </ul>
    </div>
    )}


  </div>
  )
}

export default PhdAlumini