import React from 'react'
import blank from '../images/blank.png'

function Collaborators() {

  const collaborators= [
    {
        Name: "David Taniar",  
        University:"Monash University, Monash",
        Country: "Australia",
        website: "https://research.monash.edu/en/persons/david-taniar",
        
    },

    {
        Name: "Le Hoang Son",  
        University:"Vietnam National University, Hanoi",
        Country: "Vietnam",
        website: "https://orcid.org/0000-0001-6356-0046",
        
    },

    {
        Name: "Ali Ahmadian",  
        University:"Universiti Putra Malaysia, Selangor",
        Country: "Malaysia",
        website: "/",
        
    },

    {
        Name: "Ahmed Mohamed Aziz Ismail",  
        University:"Benha University",
        Country: "Egypt",
        website: "https://bu.edu.eg/staff/ahmedaziz14",
        
    },

    {
        Name: "Mohamed Abdel-Basset",  
        University:"Zagazig University",
        Country: "Egypt",
        website: "http://www.staffdata.zu.edu.eg/mohamedbasset/wp-content/uploads/CV_2020.pdf",
        
    },

    {
        Name: "Ahmed Mohamed Aziz Ismail",  
        University:"Benha University",
        Country: "Egypt",
        website: "https://bu.edu.eg/staff/ahmedaziz14",
        
    },

    {
        Name: "Soheil Salahshour",  
        University:"Bahcesehir University",
        Country: "Turkey",
        website: "https://bahcesehir.academia.edu/SoheilSalahshour",
        
    },

    {
      Name: "Anura P. Jayasumana",  
      University:"Colorado State University, Fort Collins, Colorado",
      Country: "USA",
      website: "https://www.engr.colostate.edu/~anura/",
      
    },

    {
      Name: "Walid Osamy",  
      University:"Benha University",
      Country: "Egypt",
      website: "https://www.bu.edu.eg/staff/walidosamy14",
      
    },

    {
      Name: "Mehdi Salimi",  
      University:"Simon Fraser University",
      Country: "Canada",
      website: "https://wwwpub.zih.tu-dresden.de/~salimi/",
      
    },

    {
      Name: "Norazak Senu",  
      University:"University Putra Malaysia, Selangor,",
      Country: "Malaysia",
      website: "https://profile.upm.edu.my/norazak",
      
    },

    {
      Name: "Prof.  Laura Emilia Maria Ricci,",  
      University:"University of Pisa, Largo Bruno Pontecorvo, Pisa",
      Country: "Italy",
      website: "https://pages.di.unipi.it/ricci/",
      
    },

    {
      Name: "KHAIROL AMALI BIN AHMAD",  
      University:"NATIONAL DEFENCE UNIVERSITY OF MALAYSIA",
      Country: "MALAYSIA",
      website: "https://www.researchgate.net/profile/Khairol-Amali-Ahmad",
      
    },

    {
      Name: "ALI AHMADIAN",  
      University:"CENTRAL QUEENSLAND UNIVERSITY, ROCKHAMPTON,",
      Country: "AUSTRALIA",
      website: "https://www.decisionslab.eu/membro-team/ali-ahmadian/",
      
    },

    {
      Name: "SERGEY BEZZATEEV",  
      University:"ITMO UNIVERSITY, ST. PETERSBURG,",
      Country: "RUSSIA",
      website: "https://cit.itmo.ru/en/viewperson/153954/bezzateev-sergei-valentinovich.htm",
      
    },

    {
      Name: "SATVIK VATS",  
      University:"GRAPHIC ERA HILL UNIVERSITY",
      Country: "INDIA",
      website: "https://dehradun.gehu.ac.in/computer-science-and-engineering/faculty/dr-satvik-vats/",
      
    },

    {
      Name: "VIKRANT SHARMA",  
      University:"GRAPHIC ERA HILL UNIVERSITY",
      Country: "INDIA",
      website: "https://dehradun.gehu.ac.in/computer-science-and-engineering/faculty/dr-vikrant-sharma/",
      
    },

    {
      Name: "MANISHA MANJUL",  
      University:"G. B. PANT DSEU CAMPUS-I",
      Country: "INDIA",
      website: "https://dseu.ac.in/faculties/manisha-manjul/index.html",
      
    },

    {
      Name: "HEHZAD ASHRAF CHAUDHRY",  
      University:"Abu Dhabi University (ADU)",
      Country: "UAE",
      website: "https://www.researchgate.net/profile/Shehzad-Chaudhry",
      
    },

    {
      Name: "Prof. Ashok Kumar Das",  
      University:"Indian Institute of Information Technology, Hyderabad",
      Country: "India",
      website: "https://sites.google.com/view/iitkgpakdas/",
      
    },

    {
      Name: "Prof. Muhammad Khurram Khan",  
      University:"King Saud University",
      Country: "Saudi Arabia.",
      website: "https://www.professorkhurram.com/",
      
    },

    {
      Name: "Prof. Shivakumar Mathapathi",  
      University:"UC San Diego Extn",
      Country: "USA",
      website: "https://extendedstudies.ucsd.edu/about-extension/shivakumar-mathapathi",
      
    }



  ]  
  return (

    <div className="container">
        <h1 className="text-center" style={{color:"#4437B8"}}>Collaborators</h1>

        <div className="my-4">
      <div className="row row-cols-1 row-cols-md-3 g-4">

        {collaborators.map((e,index)=>(

          <div className="col">
          <div className="card h-80 shadow p-3 mb-5 bg-white rounded">
          <img key={e.Image} src={blank} className="card-img-top rounded-circle mx-auto d-block my-3" alt="..." style={{height:"50%", width:"50%",borderRadius:"50%"}} />
            <div className="card-body">
              <h5 className="card-title text-center">{e.Name}</h5>
              <p className="card-text text-center">{e.University}</p>
              <p className="card-text text-center">{e.Country}</p>
              <div class="d-grid gap-2">
                <a href={e.website} target="_blank" class="btn btn-custom" type="button">View more</a>
                
              </div>
            </div>
          </div>
          </div>


        ))}
        
      </div>

      </div>


    </div>
  )
}

export default Collaborators