import React from 'react'
import netcrypt from '../Event_Fotos/Netcrypt2025.png'

function UpcomingEvent() {
  return (
    
    <div className="container my-5">
        <h1 className='text-center' style={{color: "#4437B8"}}> <strong>INTERNATIONAL CONFERENCE ON NETWORKS AND CRYPTOLOGY</strong> </h1>
        <h1 className='text-center' style={{color: "#4437B8"}}> <strong>2025</strong> </h1>

        <div class="card text-bg-dark my-5 border-0">
          <img src={netcrypt} class="card-img" alt="..." />
        </div>

        <h3 className='text-center' style={{color: "#4437B8"}}> <strong>Registration will start soon....</strong> </h3>

    </div>
    
  )
}

export default UpcomingEvent